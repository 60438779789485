// @ts-nocheck

import React, { Component, Fragment } from 'react';

import './root.scss';

import { updateTitle } from '../helpers/other/strings';
import { getEnvelope } from '../wallet/envelope/envelope.service';
import RootProvider, { RootContext, StateContext } from './root.provider.tsx';
import { isMobile } from '@/helpers/device';
import { EnvelopeModel } from '@/models/envelopeModel.ts';
import { T } from '@/helpers/i18n';
import Spinner from '@/partials/spinner/spinner.tsx';

type State = {
  loading: boolean;
  envelope: EnvelopeModel;
  err: any;
  Component: React.ComponentType;
};

class Root extends Component<T, State> {
  static contextType: React.Context<StateContext> = RootContext;
  token: string = window.location.pathname.substring(1);

  state: State = {
    loading: true,
    envelope: null,
    err: null,
    components: null,
  };

  async componentDidMount() {
    if (this.token) {
      try {
        const { data: envelope } = await getEnvelope(this.token);
        this.setState({ envelope: envelope });
      } catch (err: any) {
        this.setState({ err: err })
      } finally {
        const useNewUI = this.state.envelope?.featureFlags?.['ui-v2'];
        await this.loadUIComponents(useNewUI);
      }
    } else {
      await this.loadUIComponents(false);
    }
    this.setState({ loading: false });
  }

  async loadUIComponents(useNewUI: boolean) {
    if (useNewUI) {
      const [{ default: WalletV2 }, { default: HeaderV2 }, { default: DesktopV2 }] = await Promise.all([
        import('../v2/wallet/wallet.tsx'),
        import('../v2/header/header.tsx'),
        import('../v2/desktop/desktop.tsx'),
      ]);
      this.setState({ components: { Wallet: WalletV2, Header: HeaderV2, Desktop: DesktopV2 } });
    } else {
      const [{ default: Wallet }, { default: Header }, { default: Desktop }] = await Promise.all([
        import('../wallet/wallet.tsx'),
        import('../header/header.tsx'),
        import('../desktop/desktop.tsx'),
      ]);
      this.setState({ components: { Wallet, Header, Desktop } });
    }
  }

  render() {
    updateTitle();

    const { loading, envelope, err, components } = this.state;
    const isMobileDevice: boolean = isMobile.any();

    if (loading || !components) {
      return isMobileDevice ? <></> : <Spinner isShown={true} />;
    }

    const { Wallet, Header, Desktop } = components;

    return (
      <RootProvider>
        {!isMobileDevice ? (
          <Desktop envelope={envelope} err={err} />
        ) : (
          <Fragment>
            <Header />
            <Wallet envelope={envelope} err={err} />
          </Fragment>
        )}
      </RootProvider>
    );
  }
}

export default Root;
